import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { environment } from '../../../../environments/environment';
import {TodoService} from '../../../todo.service';
import {ToastrModule} from 'ngx-toastr';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  name = environment.application.name;
  angular = environment.application.angular;
  bootstrap = environment.application.bootstrap;
  fontawesome = environment.application.fontawesome;

  features: any;
  similarity: any;
  reportpreview: any;
  input1: any;
  input2: any;
  target: any;
  source: any;
  message: any;
  original: any;
  fileList: FileList;
  translator: any;
  url: string;
  fileName: string;
  uuid: string;
  showOrHide: boolean;
  showOrHide1: boolean;
  mask: boolean;
  maskOpp: boolean;
  checkOr: boolean;
  checkTr: boolean;
  hiddenLogin: boolean;
  mail: string;
  pwd: string;
  loginMessage: string;
  showLogin: boolean;
  loginUser: string;

  constructor(
    private meta: Meta,
    private todoSrv: TodoService,
    private titleService: Title) {
    this.features =
      [];

  }

  ngOnInit(): void {
    this.titleService.setTitle('反查機器翻譯檢查系統');
    this.meta.addTag({
      name: 'author',
      content: 'danny ganatan'
    });
    this.meta.updateTag(
      {
        name: 'description',
        content: 'Cette application a été développée avec Angular version 11.0.5 et bootstrap 5.0.0' +
          ' Elle applique le Routing, le Lazy loading, le Server side rendering et les Progressive Web App (PWA)'
      });
    this.source = '';
    this.target = '';
    this.reportpreview = '';
    this.mask = true;
    this.maskOpp = false;
    this.checkOr = true;
    this.checkTr = true;
    this.hiddenLogin = false;
    this.showLogin = true;
  }

  postTodo(): any{
    if (this.source.toString() === '' && this.target.toString() !== ''){
      this.checkOr = false;
      this.checkTr = true;
      return;
    }
    if (this.source.toString() !== '' && this.target.toString() === ''){
      this.checkOr = true;
      this.checkTr = false;
      return;
    }
    if (this.source.toString() === '' && this.target.toString() === ''){
      this.checkOr = false;
      this.checkTr = false;
      return;
    }
    else{
      this.mask = false;
      this.maskOpp = true;
      this.todoSrv.postTodo(this.input1, this.input2, this.source, this.target, this.original, this.translator, this.loginUser)
        .subscribe(res => {
          this.similarity = res.similarityValue;
          this.reportpreview = res.reportpreview;
          this.message = res.mgs;
          this.uuid = res.uuid;
          this.mask = true;
          this.maskOpp = false;
          console.log(res);
        });
    }
  }

  originalCustomerUpload(event): any {
    this.fileList = event.target.files;
    this.todoSrv.originalCustomerUpload(this.fileList)
      .subscribe(res => {
        this.showOrHide1 = res.ShowOrHide;
        this.message = res.mgs;
        console.log(res);
      });
  }

  translationUpload(event): any  {
    this.fileList = event.target.files;
    this.todoSrv.translationUpload(this.fileList)
      .subscribe(res => {
        this.showOrHide = res.ShowOrHide;
        this.message = res.mgs;
        console.log(res);
      });
  }

  // download(): any {
  //   this.todoSrv.download(this.uuid)
  //     .subscribe(res => {
  //       this.message = res.mgs;
  //       console.log(res);
  //       // this.fileName = 'report';
  //       // const a = document.createElement('a');
  //       // const objectUrl = URL.createObjectURL(blob);
  //       // a.href = objectUrl;
  //       // a.download = this.fileName;
  //       // a.click();
  //       // URL.revokeObjectURL(objectUrl);
  //     });
  // }


  download(): any {
    this.todoSrv.download(this.uuid)
      .subscribe(data => {
        this.fileName = 'report';
        const blob = new Blob([data], {type: 'application/msword'});
        const a = document.createElement('a');
        const objectUrl = URL.createObjectURL(blob);
        a.href = objectUrl;
        a.download = this.fileName;
        a.click();
        URL.revokeObjectURL(objectUrl);
      });
  }

  clear(): any {
    this.message = '';
    this.uuid = '';
    this.target = '';
    this.input1 = '';
    this.input2 = '';
    this.fileName = '';
    this.showOrHide = false;
    this.showOrHide1 = false;
    this.similarity = '';
    this.reportpreview = '';
    this.source = '';
    this.target = '';
    this.original = '';
    this.translator = '';
    this.checkOr = true;
    this.checkTr = true;
  }

  login(): any{
    this.todoSrv.login(this.mail, this.pwd)
      .subscribe(res => {
        const loginStatus = res.status;
        if (loginStatus === '0'){
          this.loginMessage = res.mgs;
        }else{
          this.showLogin = false;
          this.hiddenLogin = true;
          this.loginUser = res.user;
        }
        console.log(res);
      });
  }

  onEnter(): void {
    this.login();
  }
}
