import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {filter} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class TodoService {
  url =  'https://backend-vbvbhk2moq-de.a.run.app/ptsgiChecker/';
  originalCustomerUpUrl =  '/uploadFiles/';
  translationUpUrl =  '/translationUploadFiles/';
  downloadFileUrl = '/downloadFile/';
  excelFileUpUrl =  '/uploadExcelFiles/';
  public data: any;
  constructor(
    private http: HttpClient,
  ) {

  }

  postTodo(originalCustomer: string, translationDivision: string, source: string, target: string,
           originalFile: string, translatorFile: string, user: string ): any{
    const original = originalCustomer;
    const translation = translationDivision;
    const sou = source;
    const tar = target;
    const originalFiles = originalFile;
    const translatorFiles = translatorFile;
    const formData: FormData = new FormData();
    formData.append('value1', original);
    formData.append('value2', translation);
    formData.append('source', sou);
    formData.append('target', tar);
    formData.append('originalF', originalFiles);
    formData.append('translatorF', translatorFiles);
    formData.append('user', user);
    const similarity = this.http.post(this.url, formData);
    return similarity;
  }

  originalCustomerUpload(fileList: FileList): any{
    const fileL = fileList;

    const formData: FormData = new FormData();
    if (fileL.length > 0) {
      const file: File = fileL[0];
      formData.append('file', file);
      // formData.append('name', file.name );
      const upload = this.http.post(this.originalCustomerUpUrl, formData);
      return upload;
    }
  }

  translationUpload(fileList: FileList): any{
    const fileT = fileList;

    const formData: FormData = new FormData();
    if (fileT.length > 0) {
      const file: File = fileT[0];
      formData.append('file', file);
      // formData.append('name', file.name );
      const upload = this.http.post(this.translationUpUrl, formData);
      return upload;
    }
  }

  download(uuid: string): Observable<Blob> {
    const formData: FormData = new FormData();
    const fileName: any = 'report.docx';
    const uuidStr: any = uuid;
    formData.append('value', fileName);
    formData.append('uuid', uuidStr);
    return this.http.post(this.downloadFileUrl, formData, {responseType: 'blob'});
  }

  fileExcelUpload(fileList: FileList): any {
    const fileL = fileList;
    const formData: FormData = new FormData();
    if (fileL.length > 0) {
      const file: File = fileL[0];
      formData.append('value', file);
      formData.append('name', file.name );
      const upload = this.http.post(this.excelFileUpUrl, formData);
      return upload;
    }
  }
  // download(uuid: string): any {
  //   const formData: FormData = new FormData();iul
  //   const fileName: any = 'report.docx';
  //   const uuidStr: any = uuid;
  //   formData.append('value', fileName);
  //   formData.append('uuid', uuidStr);
  //   return this.http.post(this.downloadFileUrl, formData);
  // }
  login(mail: string, pwd: string): any {
    const url = 'https://backend-vbvbhk2moq-de.a.run.app/login/';
    const formData: FormData = new FormData();
    formData.append('mail', mail);
    formData.append('pwd', pwd);
    const login = this.http.post(url, formData);
    return login;
  }
}
