import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Renderer2 } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'angular-starter';
  version = 'Angular version 11.0.5';
  items = [
    {
      name: 'Home',
      elements: [
        { name: '反查機器翻譯檢查系統', link: '' },
        { name: '自動解析結果查看', link: 'charts' },
      ]
    },
    // {
    //   name: 'About', link: 'Features',
    //   elements: [
    //     { name: 'About', link: 'charts' },
    //   ]
    // },
  ];

  constructor(
    public router: Router,
    public renderer: Renderer2) { }

  onSelectMenu(link: any): void {
    const element = document.getElementById('bd-docs-nav');
    this.renderer.removeClass(element, 'show');
    const route = '/' + link;
    this.router.navigate([route]);
  }


}

