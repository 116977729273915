<!doctype html>

<html lang="zh-TW">
<head>
  <link rel="Shortcut Icon" type="image/x-icon" href="favicon.ico"/>
  <!-- Global site tag (gtag.js) - Google Analytics -->
  <script async src="https://www.googletagmanager.com/gtag/js?id=G-EP8SM66PTF"></script>
  <script>
    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());

    gtag('config', 'G-EP8SM66PTF');
  </script>
  <link rel="preconnect" href="https://fonts.gstatic.com">
  <link href="https://fonts.googleapis.com/css2?family=Noto+Sans+TC:wght@100&display=swap" rel="stylesheet">
  <style>
    /*h1 {*/
    /*  font-family: 'Noto Sans TC', sans-serif;*/
    /*}*/
    .nav{
      font-size: 40px;
      font-weight: 300;
    }
    .col{
      position: relative;
    }
    .icon-user{
      position: absolute;left: 20px;top: 10px;
      background-image: url("assets/params/images/demo/upload.svg");
      background-repeat: no-repeat;
      background-position: 0px 0px;
      width: 20px;
      height: 20px;
    }
    .footer{
      position:fixed;
      bottom:0;
      left:0;
      width: 100%;
    }
  </style>

  <!-- Required meta tags -->
  <meta charset="utf-8">

  <!-- Bootstrap CSS -->
  <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@4.6.0/dist/css/bootstrap.min.css" integrity="sha384-B0vP5xmATw1+K9KRQjQERJvTumQW0nPEzvF6L/Z6nronJ3oUOFUFpCjEUQouq2+l" crossorigin="anonymous">
  <title>反查機器翻譯檢查系統</title>
</head>

<!-- 登錄畫面 -->
<div class="card" [hidden]="hiddenLogin">
  <div class="col-12 d-flex align-items-center justify-content-md-center"> <img src="assets/params/images/demo/police.svg" height="45" class="mr-2" alt="Logo">
    <p class="nav">反查機器翻譯檢查系統</p>
  </div>
  <div class="btt col">
    <div class="form-group">
      <div class="col-sm-offset-4 col-sm-3">
        <h2 class="title">登錄</h2>
      </div>
    </div>
    <div class="form-group">
      <div class="col-sm-offset-4 col-sm-3">
        <input type="text" class="form-control" name="username" value="" placeholder="輸入Email" [(ngModel)]="mail">
      </div>

    </div>
    <div class="form-group">
      <div class="col-sm-offset-4 col-sm-3">
        <input type="password" class="form-control" name="password" value="" placeholder="密碼" [(ngModel)]="pwd" (keyup.enter)="onEnter()">
        <p>
          <span style="color: red;size: A3" [innerHTML]='loginMessage'></span>
        </p>
      </div>
    </div>
    <div class="form-group">
      <div class="col-sm-offset-4 col-sm-3">
        <button id="logon" class="btn btn-primary" (click)="login()">登錄</button>
      </div>
    </div>
  </div>
</div>

<!-- 主畫面 -->
<div disabled="true" [hidden]="showLogin">
  <div [hidden]="true">
    <span [innerHTML]="loginUser" [(ngModel)]="loginUser"></span>
  </div>
  <div class="row">
    <div class="col-12 d-flex align-items-center justify-content-md-center"> <img src="assets/params/images/demo/police.svg" height="45" class="mr-2" alt="Logo">
      <p class="nav">反查機器翻譯檢查系統</p>
    </div>
  </div>
  <p></p>
  <div class="container">
    <div class="row">
      <div class="col">
        <div class="input-group mb-3">
          <div class="input-group-prepend">
            <label class="input-group-text">原文</label>
          </div>
          <select class="custom-select" [(ngModel)]="source" ng-init="selectedName = names[0]" ng-model="selectedName" ng-options="x for x in names">
            <option value="">請選擇...</option>
            <option value="en">English</option>
            <option value="zh-TW">Chinese (Traditional)</option>
            <option value="zh-CN">Chinese (Simplified)</option>
            <option value="ja">Japanese</option>
            <option value="ko">Korean</option>
            <option value="de">German</option>
            <option value="fr">French</option>
            <option value="th">Thai</option>
            <option value="es">Spanish</option>
            <option value="it">Italian</option>
            <option value="af">Afrikaans</option>
            <option value="sq">Albanian</option>
            <option value="am">Amharic</option>
            <option value="ar">Arabic</option>
            <option value="hy">Armenian</option>
            <option value="az">Azeri (Latin)</option>
            <option value="eu">Basque</option>
            <option value="be">Belarusian</option>
            <option value="bn">Bengali</option>
            <option value="bs">Bosnian</option>
            <option value="bg">Bulgarian</option>
            <option value="my">Burmese</option>
            <option value="ca">Catalan</option>
            <option value="ceb">Cebuano</option>
            <option value="ny">Chewa</option>
            <option value="co">Corsican</option>
            <option value="hr">Croatian</option>
            <option value="cs">Czech</option>
            <option value="da">Danish</option>
            <option value="nl">Dutch</option>
            <option value="eo">Esperanto</option>
            <option value="et">Estonian</option>
            <option value="fa">Farsi</option>
            <option value="fi">Finnish</option>
            <option value="gl">Galician</option>
            <option value="ka">Georgian</option>
            <option value="el">Greek</option>
            <option value="gu">Gujarati</option>
            <option value="ht">Haitian Creole</option>
            <option value="ha">Hausa</option>
            <option value="haw">Hawaiian</option>
            <option value="hi">Hindi</option>
            <option value="hmn">Hmong</option>
            <option value="hu">Hungarian</option>
            <option value="is">Icelandic</option>
            <option value="ig">Igbo</option>
            <option value="id">Indonesian</option>
            <option value="ga">Irish</option>
            <option value="jv">Javanese</option>
            <option value="kn">Kannada</option>
            <option value="kk">Kazakh</option>
            <option value="km">Khmer</option>
            <option value="rw">Kinyarwanda</option>
            <option value="ku">Kurdish</option>
            <option value="ky">Kyrgyz</option>
            <option value="lo">Lao</option>
            <option value="la">Latin</option>
            <option value="lv">Latvian</option>
            <option value="lt">Lithuanian</option>
            <option value="lb">Luxembourgish</option>
            <option value="mk">Macedonian</option>
            <option value="mg">Malagasy</option>
            <option value="ms">Malay</option>
            <option value="ml">Malayalam</option>
            <option value="mt">Maltese</option>
            <option value="mi">Maori</option>
            <option value="mr">Marathi</option>
            <option value="mn">Mongolian</option>
            <option value="ne">Nepali</option>
            <option value="no">Norwegian</option>
            <option value="or">Oriya</option>
            <option value="ps">Pashto</option>
            <option value="pl">Polish</option>
            <option value="pt">Portuguese</option>
            <option value="pa">Punjabi</option>
            <option value="ro">Romanian</option>
            <option value="ru">Russian</option>
            <option value="sm">Samoan</option>
            <option value="gd">Scottish Gaelic</option>
            <option value="sr">Serbian</option>
            <option value="sn">Shona</option>
            <option value="sd">Sindhi</option>
            <option value="si">Sinhala</option>
            <option value="sk">Slovak</option>
            <option value="sl">Slovenian</option>
            <option value="so">Somali</option>
            <option value="st">Southern Sotho</option>
            <option value="su">Sundanese</option>
            <option value="sw">Swahili</option>
            <option value="sv">Swedish</option>
            <option value="tl">Tagalog</option>
            <option value="tg">Tajik</option>
            <option value="ta">Tamil</option>
            <option value="tt">Tatar</option>
            <option value="te">Telugu</option>
            <option value="tr">Turkish</option>
            <option value="tk">Turkmen</option>
            <option value="ug">Uyghur</option>
            <option value="uk">Ukrainian</option>
            <option value="ur">Urdu</option>
            <option value="uz">Uzbek (Latin)</option>
            <option value="vi">Vietnamese</option>
            <option value="cy">Welsh</option>
            <option value="fy">Western Frisian</option>
            <option value="xh">Bantu</option>
            <option value="yi">Yiddish</option>
            <option value="yo">Yoruba</option>
            <option value="zu">Zulu</option>
          </select>
        </div>
        <div [hidden]="checkOr">
          <span style="color: red;size: A3">請選擇原文類別！</span>
        </div>
      </div>
      <div class="col col-2"><br>
        <br>
      </div>
      <div class="col">
        <div class="input-group mb-3">
          <div class="input-group-prepend">
            <label class="input-group-text">譯文</label>
          </div>
          <select class="custom-select" [(ngModel)]="target" ng-init="selectedName = names[0]" ng-model="selectedName" ng-options="x for x in names" id="validationCustom99">
            <option value="">請選擇...</option>
            <option value="en">English</option>
            <option value="zh-TW">Chinese (Traditional)</option>
            <option value="zh-CN">Chinese (Simplified)</option>
            <option value="ja">Japanese</option>
            <option value="ko">Korean</option>
            <option value="de">German</option>
            <option value="fr">French</option>
            <option value="th">Thai</option>
            <option value="es">Spanish</option>
            <option value="it">Italian</option>
            <option value="af">Afrikaans</option>
            <option value="sq">Albanian</option>
            <option value="am">Amharic</option>
            <option value="ar">Arabic</option>
            <option value="hy">Armenian</option>
            <option value="az">Azeri (Latin)</option>
            <option value="eu">Basque</option>
            <option value="be">Belarusian</option>
            <option value="bn">Bengali</option>
            <option value="bs">Bosnian</option>
            <option value="bg">Bulgarian</option>
            <option value="my">Burmese</option>
            <option value="ca">Catalan</option>
            <option value="ceb">Cebuano</option>
            <option value="ny">Chewa</option>
            <option value="co">Corsican</option>
            <option value="hr">Croatian</option>
            <option value="cs">Czech</option>
            <option value="da">Danish</option>
            <option value="nl">Dutch</option>
            <option value="eo">Esperanto</option>
            <option value="et">Estonian</option>
            <option value="fa">Farsi</option>
            <option value="fi">Finnish</option>
            <option value="gl">Galician</option>
            <option value="ka">Georgian</option>
            <option value="el">Greek</option>
            <option value="gu">Gujarati</option>
            <option value="ht">Haitian Creole</option>
            <option value="ha">Hausa</option>
            <option value="haw">Hawaiian</option>
            <option value="hi">Hindi</option>
            <option value="hmn">Hmong</option>
            <option value="hu">Hungarian</option>
            <option value="is">Icelandic</option>
            <option value="ig">Igbo</option>
            <option value="id">Indonesian</option>
            <option value="ga">Irish</option>
            <option value="jv">Javanese</option>
            <option value="kn">Kannada</option>
            <option value="kk">Kazakh</option>
            <option value="km">Khmer</option>
            <option value="rw">Kinyarwanda</option>
            <option value="ku">Kurdish</option>
            <option value="ky">Kyrgyz</option>
            <option value="lo">Lao</option>
            <option value="la">Latin</option>
            <option value="lv">Latvian</option>
            <option value="lt">Lithuanian</option>
            <option value="lb">Luxembourgish</option>
            <option value="mk">Macedonian</option>
            <option value="mg">Malagasy</option>
            <option value="ms">Malay</option>
            <option value="ml">Malayalam</option>
            <option value="mt">Maltese</option>
            <option value="mi">Maori</option>
            <option value="mr">Marathi</option>
            <option value="mn">Mongolian</option>
            <option value="ne">Nepali</option>
            <option value="no">Norwegian</option>
            <option value="or">Oriya</option>
            <option value="ps">Pashto</option>
            <option value="pl">Polish</option>
            <option value="pt">Portuguese</option>
            <option value="pa">Punjabi</option>
            <option value="ro">Romanian</option>
            <option value="ru">Russian</option>
            <option value="sm">Samoan</option>
            <option value="gd">Scottish Gaelic</option>
            <option value="sr">Serbian</option>
            <option value="sn">Shona</option>
            <option value="sd">Sindhi</option>
            <option value="si">Sinhala</option>
            <option value="sk">Slovak</option>
            <option value="sl">Slovenian</option>
            <option value="so">Somali</option>
            <option value="st">Southern Sotho</option>
            <option value="su">Sundanese</option>
            <option value="sw">Swahili</option>
            <option value="sv">Swedish</option>
            <option value="tl">Tagalog</option>
            <option value="tg">Tajik</option>
            <option value="ta">Tamil</option>
            <option value="tt">Tatar</option>
            <option value="te">Telugu</option>
            <option value="tr">Turkish</option>
            <option value="tk">Turkmen</option>
            <option value="ug">Uyghur</option>
            <option value="uk">Ukrainian</option>
            <option value="ur">Urdu</option>
            <option value="uz">Uzbek (Latin)</option>
            <option value="vi">Vietnamese</option>
            <option value="cy">Welsh</option>
            <option value="fy">Western Frisian</option>
            <option value="xh">Bantu</option>
            <option value="yi">Yiddish</option>
            <option value="yo">Yoruba</option>
            <option value="zu">Zulu</option>
          </select>
        </div>
        <div [hidden]="checkTr">
          <span style="color: red;size: A3">請選擇譯文類別！</span>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <i class="icon-user"></i>
        <input type="button" class="btn btn-outline-primary" src="assets/params/images/demo/upload.svg" value="&nbsp;&nbsp;上傳客戶原文" size="40"  onclick="b.click()"> &nbsp;
        <input type="text" id="txt2" name="txt2" [(ngModel)]="original" style="border: none">
        <input [(ngModel)]="original" type="file" nv-file-select uploader="uploader" id="b"
               onchange="txt2.value=this.value"
               (change)="originalCustomerUpload($event)"
               name="f" style="position:absolute;filter:alpha(opacity=0);mso-hide: all" size="1"
               [disabled]="showOrHide" hidden>
      </div>
      <div class="col col-2 text-center"> </div>
      <div class="col">
        <i class="icon-user"></i>
        <input type="button" class="btn btn-outline-primary" onmousemove="f.style.pixelLeft=event.x-60;f.style.pixelTop=this.offsetTop;" value="&nbsp;&nbsp;上傳翻譯師譯文" size="40" onclick="a.click()"> &nbsp;
        <input type="text" id="txt1" name="txt1" [(ngModel)]="translator" style="border: none">
        <input [(ngModel)]="translator" type="file" id="a"
               onchange="txt1.value=this.value" (change)="translationUpload($event)"
               name="f" style="position:absolute;filter:alpha(opacity=0);mso-hide: all" size="1"
               [disabled]="showOrHide1" hidden>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <p></p>
        <textarea [(ngModel)]="input1" class="form-control" id="exampleFormControlTextarea1" rows="10" placeholder="貼上客戶原文 (最多 5,000 字元)"></textarea>
      </div>
      <div class="col col-2 text-center">
        <p></p>
        <div class="start">
          <button class="btn btn-primary" type="submit" (click)="postTodo()" [hidden]="maskOpp"><img src="assets/params/images/demo/search.svg" alt="開始分析"/>&nbsp;開始分析</button>
          <br>
          <button class="btn btn-primary" type="button" disabled [hidden]="mask">
            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            <span>&nbsp;正在分析</span>
          </button>
        </div>
        <div class="sim">
          <p></p>
          <span [innerHTML]='similarity' style="width: 80px" name="xiangsidu" id="xiangsidu"></span>
          <p></p>
        </div>
        <div class="download">
          <button type="button" class="btn btn-primary" (click)="download()"><img src="assets/params/images/demo/download.svg" alt="下載報告"/>&nbsp;下載報告</button>
        </div>
        <div class="restart">
          <p></p>
          <button type="button" class="btn btn-primary" (click)="clear()"><img src="assets/params/images/demo/restart.svg" alt="清除畫面"/>&nbsp;清除畫面</button>
        </div>
        <p></p>
        <!--      <div class="col text-center alert alert-danger fade show" role="alert">分析失敗！</div>-->
      </div>
      <div class="col">
        <p></p>
        <textarea [(ngModel)]="input2" class="form-control" id="exampleFormControlTextarea2" rows="10" placeholder="貼上翻譯師譯文"></textarea>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <p></p>
        <label for="exampleFormControlTextarea1"><img src="assets/params/images/demo/preview.svg" alt="預覽報告"/>&nbsp;預覽報告</label>
        <div [innerHTML] = 'reportpreview | html'></div>
        <!--      <textarea class="form-control" id="exampleFormControlTextarea3" rows="10"></textarea>-->
      </div>
    </div>
    <p></p>
    <div class="footer" style="background-color:#1976d2">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <p class="text-center text-white">© 2021 PTSGI Inc.</p>
          </div>
        </div>
      </div>
    </div>
    <!--  <div style="text-align:center">&copy; 2021 PTSGI Inc.</div>-->
  </div>
</div>

<script src="https://code.jquery.com/jquery-3.5.1.slim.min.js" integrity="sha384-DfXdz2htPH0lsSSs5nCTpuj/zy4C+OGpamoFVy38MVBnE+IbbVYUew+OrCXaRkfj" crossorigin="anonymous"></script>
<script src="https://cdn.jsdelivr.net/npm/bootstrap@4.6.0/dist/js/bootstrap.bundle.min.js" integrity="sha384-Piv4xVNRyMGpqkS2by6br4gNJ7DXjqk09RmUpJ8jgGtD7zP9yug3goQfGII0yAns" crossorigin="anonymous"></script>
</html>
