<!--<header>-->

<!--  <nav class="navbar navbar-expand-md navbar-dark fixed-top bd-navbar" style="background-color: #212121;">-->
<!--    <div class="container-fluid">-->
<!--      <a class="navbar-brand" routerLink="/">-->
<!--        <span class="mr-1">PTSGI  </span>-->
<!--        <img src="assets/params/images/logo/ptsgi_Logo.png" width="200" height="30" alt="">-->
<!--      </a>-->
<!--      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#bd-docs-nav"-->
<!--        aria-controls="navbarsExampleDefault" aria-expanded="false" aria-label="Toggle navigation">-->
<!--        <span class="navbar-toggler-icon"></span>-->
<!--      </button>-->
<!--      <div class="collapse navbar-collapse" id="navbarsExampleDefault">-->
<!--        <ul class="navbar-nav mx-auto">-->
<!--          <li class="nav-item active">-->
<!--            <a class="nav-link text-white" routerLink="/">-->
<!--              <i class="fas fa-home mr-1"></i>Home-->
<!--            </a>-->
<!--          </li>-->
<!--          <li class="nav-item active">-->
<!--            <a class="nav-link text-white" routerLink="/about">-->
<!--              <i class="far fa-question-circle mr-1"></i>About-->
<!--            </a>-->
<!--          </li>-->
<!--          <li class="nav-item active">-->
<!--            <a class="nav-link text-white" routerLink="/contact">-->
<!--              <i class="fas fa-envelope mr-1"></i>Contact-->
<!--            </a>-->
<!--          </li>-->
<!--        </ul>-->
<!--      </div>-->
<!--    </div>-->
<!--  </nav>-->


<!--</header>-->

<!--<main class="">-->
<!--  <div class="container-xxl bd-layout">-->
<!--    <div class="bd-sidebar">-->
<!--      <nav class="collapse bd-links pt-4" id="bd-docs-nav">-->
<!--        <ul class="list-unstyled mb-0">-->
<!--          <li class="my-1 active d-lg-none">-->
<!--            <a class="d-inline-flex align-items-center rounded" data-bs-toggle="collapse" href="#ganatan-collapse"-->
<!--              role="button" aria-expanded="true" aria-current="true">-->
<!--              About Ganatan-->
<!--            </a>-->
<!--            <ul class="list-unstyled font-weight-normal mb-2 collapse" id="ganatan-collapse">-->
<!--              <li><a (click)="onSelectMenu('about')" class="d-inline-flex align-items-center rounded active"-->
<!--                  aria-current="page">About</a>-->
<!--              </li>-->
<!--              <li><a (click)="onSelectMenu('contact')" class="d-inline-flex align-items-center rounded">Contact</a></li>-->
<!--            </ul>-->
<!--          </li>-->
<!--          <li *ngFor="let item of items" class="my-1 active">-->
<!--            <a class="d-inline-flex align-items-center rounded" data-bs-toggle="collapse" href="#{{ item.link }}-collapse"-->
<!--              role="button" aria-expanded="true" aria-current="true">-->
<!--              {{ item.name }}-->
<!--            </a>-->
<!--            <ul class="list-unstyled font-weight-normal mb-2 collapse" id="{{ item.link }}-collapse">-->
<!--              <li *ngFor="let element of item.elements"><a (click)="onSelectMenu(element.link)"-->
<!--                  class="d-inline-flex align-items-center rounded">{{ element.name }}</a>-->
<!--              </li>-->
<!--            </ul>-->
<!--          </li>-->
<!--        </ul>-->
<!--      </nav>-->
<!--    </div>-->
    <div class="bd-content pt-sm-2">
      <router-outlet></router-outlet>
    </div>
<!--  </div>-->
<!--</main>-->

<!--<div class="footer" style="background-color:#1976d2">-->
<!--  <div class="container">-->
<!--    <div class="row">-->
<!--      <div class="col-12">-->
<!--        <p class="text-center text-white">© 2021 PTSGI Inc.</p>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->
<!--</div>-->
